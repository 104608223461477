/**
 *
 * Contact Page
 * 
*/

import React, { Fragment } from "react"
import { withPrefix } from 'gatsby';
import { Location } from "@reach/router";

import BasePage from "./BasePage"
import Module from "../module/Module";
import ModuleBackground from "../module/ModuleBackground";
import ModuleContent from "../module/ModuleContent";
import ModuleLayer from "../module/ModuleLayer";
import TextLayer from "../module/layers/TextLayer";
import ImageLayer from "../module/layers/ImageLayer";
import RichLayoutRow from "../richlayout/RichLayoutRow";
import RichLayoutColumn from "../richlayout/RichLayoutColumn";
import RichLayoutBody from "../richlayout/RichLayoutBody";
import Footer from "../Footer";
import ScrollDown from "../scrollDown/ScrollDown";
import { 
    contactData, 
} from "../../data/PageAssets";

export default class ContactPage extends BasePage
{
    constructor(props)
    {
        super(props);

        this._isLazy = true;                        // lazy loading 
        this._assetPath = "contact";                // asset folder for page
        this._assets = contactData();               // asset data

        this.state = {
            hasLoaded: false,
            hasLazyLoaded: false,
            hasResized: false,
        }
    }

    componentDidMount()
    {
        super.componentDidMount();
        
        // start initial asset loading...
        this.startAssets({ 
            isLazy: false, 
            onAssetEnd: () => this.onAssetEnd() 
        });
    }

    onAssetEnd()
    {
        this.setState({ hasLoaded: true });
    }

    componentDidUpdate(prevProps, prevState)
    {
        const { hasLoaded, hasLazyLoaded } = this.state;

        if (hasLoaded !== prevState.hasLoaded)
        {
            this.initPage();
            return;
        }

        if (hasLazyLoaded !== prevState.hasLazyLoaded)
        {
            this.afterPageLoad();
        }
    }

    render()
    {
        const { hasLoaded, hasLazyLoaded: lz } = this.state;
        const { hasResized: rz } = this.props;

        const getVid = this.pVideoNam("contact_loop");
        const mProps = {
            hasLazyLoaded: lz,
            hasResized: rz,
            device: this._devSize
        }

        const emailLink = withPrefix(`partials/email.php?n=1`);

        return (
            <Fragment>
                <article ref={div => this._pageRef = div} className={"page contact"}>
                    <div className={"page-content"}>
                        <h1>Contact</h1>
                        <ul>
                            <li>Playa Bocana</li>
                            <li>Huatulco, Oaxaca, Mexico</li>
                            <li>Office 011 52 958 587 2643</li>
                            <li>LIC. GERARDO ANGEL LUNA</li>
                            <li>General Manager</li>
                       </ul>
                    </div>
                    {
                        hasLoaded && 
                        <div className="modules">
                    
                            <Module isHeader={true} isFullSize={true} {...mProps}>
                                <ModuleBackground 
                                    device={this._devSize}
                                    image={this.assetRef("contact_hero_shot")}
                                    video={{ desktop: getVid, delay: this._isPhone ? this._delay : 0 }}
                                />
                                <ModuleContent 
                                    textContentClass="text-align-center align-center v-bottom"
                                    isFullSize={true}
                                    heading="Contact"
                                />
                                <div ref={this._scrollRef} className="module-scroll-down show-scroll"><ScrollDown /></div>
                            </Module>

                            <ModuleLayer backgroundColor="white" {...mProps}>

                                <ImageLayer 
                                    classes="first-pad"
                                    alt="Casa Lupita top view"
                                    asset={this.assetRef("contact_top_view_casa")}
                                    x={0} y={107} width={1094} height={615}
                                />
                                
                                <TextLayer classes="casa-contact bottom-space" x={1200} y={157} width={600} height={370} titleHtml={"Casa Lupita"}>

                                    <Fragment>
                                        <ul className="contact-info no-bullets no-padding-start">
                                            <li>Playa Bocana</li>
                                            <li>Huatulco, Oaxaca, Mexico</li>
                                            <li>Office 011 52 958 587 2643</li>
                                            <li>LIC. GERARDO ANGEL LUNA</li>
                                            <li>General Manager</li>
                                            <li><Location>
                                                {({ location }) => (
                                                    <a href={`${location.origin}${emailLink}`} rel="nofollow">Email Gerardo</a>
                                                )}
                                            </Location></li>
                                        </ul>
                                    </Fragment>

                                </TextLayer>

                            </ModuleLayer>

                            <Module 
                                device={this._devSize}
                                hasRichLayout={true} 
                                classes="m-footer" 
                                backgroundColor="#0a0a0a" 
                                animate="footer"
                                hasLazyLoaded={lz}
                            >
                                <ModuleContent classes="full-width">
                                    <RichLayoutRow classes="">
                                        <RichLayoutColumn classes="flex-v-align-center">
                                            <RichLayoutBody>
                                                <Footer owner={this} pageRef={this._pageRef} hasLazyLoaded={lz} />
                                            </RichLayoutBody>
                                        </RichLayoutColumn> 
                                    </RichLayoutRow>
                                </ModuleContent>
                            </Module>

                        </div>
                    }

                </article>
            </Fragment>
        )
    }  
}